import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import styles from "./aboutUs.module.css";
import classNames from "classnames";
import Footer from "../Components/Footer";
import { FaNodeJs } from "react-icons/fa";

import {
  FaHandsHelping,
  FaLightbulb,
  FaChartLine,
  FaUserTie,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function AboutUs() {
  useEffect(() => {
    document.title = "Webware | About Us";
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleGoContact = () => {
    navigate("/contactUs");
  };
  return (
    <div>
      <NavBar />
      <div className={styles.aboutPage}>
        {/* Hero Section */}
        <div className={styles.heroSection}>
          <h1 className={styles.heading}>About Us</h1>
          <p className={styles.subHeading}>
            Innovating digital solutions to elevate your business.
          </p>
        </div>

        <div className={styles.container}>
          {/* Our Mission */}
          <section className={styles.section}>
            <h2>Our Mission</h2>
            <p>
              At Webware, our mission is to empower businesses with innovative
              digital solutions that drive growth and enhance efficiency. We are
              dedicated to delivering high-quality web and app development
              services tailored to our clients’ unique needs. Our team of
              skilled professionals is committed to fostering creativity and
              collaboration, ensuring that every project reflects our clients'
              vision.
            </p>
            <p style={{ marginTop: "16px" }}>
              We strive to exceed expectations by utilizing the latest
              technologies and industry best practices. Our goal is to build
              long-lasting relationships with our clients based on trust,
              transparency, and mutual success. We believe in continuous
              improvement and embrace change to adapt to the evolving digital
              landscape.
            </p>
            <p style={{ marginTop: "16px" }}>
              By prioritizing user experience and functionality, we aim to
              create products that resonate with audiences. Our commitment to
              customer satisfaction drives us to provide exceptional support and
              guidance throughout the project lifecycle. At Webware, we are
              passionate about helping our clients achieve their objectives and
              realize their dreams in the digital realm.
            </p>
            <p style={{ marginTop: "16px" }}>
              We recognize the importance of innovation and invest in research
              and development to stay ahead of industry trends. Our diverse team
              brings unique perspectives, fostering an environment where
              creativity thrives. We are dedicated to ethical practices,
              ensuring that our solutions are sustainable and beneficial for the
              communities we serve.
            </p>
            <p style={{ marginTop: "16px" }}>
              Through comprehensive training and skill development, we empower
              our employees to reach their full potential. We aim to make
              technology accessible to all, bridging the gap between businesses
              and their digital aspirations. Together, we create a brighter,
              more connected future, where every interaction leads to success.
            </p>
          </section>

          {/* Our Vision */}
          <section className={styles.section}>
            <h2>Our Vision</h2>
            <p>
              Our vision at Webware is to become a leading provider of digital
              solutions recognized for our creativity and innovation in web and
              app development. We aspire to be a trusted partner for businesses
              looking to thrive in the digital age, delivering transformative
              solutions that make a meaningful impact.
            </p>
            <p style={{ marginTop: "16px" }}>
              We envision a future where technology enhances lives and connects
              people seamlessly across the globe. By continuously pushing the
              boundaries of what is possible, we aim to inspire others in the
              industry and foster a culture of innovation. We believe that
              collaboration and open communication are key to achieving
              excellence and driving positive change.
            </p>
            <p style={{ marginTop: "16px" }}>
              Our vision includes cultivating a diverse and inclusive team that
              reflects the communities we serve. We are committed to
              sustainability and ethical practices, ensuring that our work
              contributes to a better world. Through our dedication to ongoing
              learning and adaptation, we will stay ahead of industry trends and
              provide unparalleled value to our clients.
            </p>
            <p style={{ marginTop: "16px" }}>
              We strive to create an environment where our employees feel
              valued, motivated, and empowered to share their ideas. Our goal is
              to build a brand synonymous with quality, creativity, and
              customer-centric solutions. We seek to transform the digital
              landscape by fostering partnerships that drive innovation and
              growth.
            </p>
          </section>

          {/* Our Values */}
          <section className={styles.section}>
            <h2>Our Values</h2>
            <div className={styles.values}>
              <div className={styles.valueCard}>
                <FaHandsHelping className={styles.icon} />
                <h3>Integrity</h3>
                <p>
                  We uphold the highest standards of integrity in all our
                  actions.
                </p>
              </div>
              <div className={styles.valueCard}>
                <FaLightbulb className={styles.icon} />
                <h3>Innovation</h3>
                <p>We foster a culture of creativity and innovation.</p>
              </div>
              <div className={styles.valueCard}>
                <FaChartLine className={styles.icon} />
                <h3>Growth</h3>
                <p>We are committed to continuous growth and learning.</p>
              </div>
            </div>
          </section>

          {/* Our Process */}
          <section className={styles.section}>
            <h2>Our Process</h2>
            <ol className={styles.processList}>
              <li>
                <strong>Discovery:</strong> Understanding your business needs
                and goals.
              </li>
              <li>
                <strong>Planning:</strong> Crafting a strategic plan tailored to
                your requirements.
              </li>
              <li>
                <strong>Design & Development:</strong> Building solutions with
                the latest technologies.
              </li>
              <li>
                <strong>Testing:</strong> Ensuring quality and performance
                through rigorous testing.
              </li>
              <li>
                <strong>Deployment:</strong> Launching your solution and
                providing ongoing support.
              </li>
            </ol>
          </section>

          {/* Technologies We Use */}
          <section className={styles.section}>
            <h2>Technologies We Use</h2>
            <div className={styles.techLogos}>
              <div className={styles.iconouter}>
                {" "}
                <img
                  src="https://miro.medium.com/v2/resize:fit:1200/1*WA_9JsyqFkge2HwYKcdJQw.png"
                  className={styles.icons}
                />{" "}
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://miro.medium.com/v2/resize:fit:900/1*TY9uBBO9leUbRtlXmQBiug.png"
                  alt="AWS"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://static-00.iconduck.com/assets.00/nextjs-icon-1024x617-rl2bcqfj.png"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://toppng.com/uploads/preview/wordpress-logo-transparent-11662328814tdhim7ggt5.png"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://www.citypng.com/public/uploads/preview/shopify-outline-bag-icon-symbol-logo-701751695132535ezprftihub.png"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://www.citypng.com/public/uploads/preview/download-html5-round-logo-icon-png-701751694771801xidtztxanv.png"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/css-icon.png"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://static.vecteezy.com/system/resources/previews/027/127/560/non_2x/javascript-logo-javascript-icon-transparent-free-png.png"
                  className={styles.icons}
                />
              </div>{" "}
              <div className={styles.iconouter}>
                <img
                  src="https://www.svgrepo.com/show/452088/php.svg"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://w7.pngwing.com/pngs/293/485/png-transparent-tailwind-css-hd-logo-thumbnail.png"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/5968/5968672.png"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZcjpXa2jw9utNc4i8_c6blmBgCSxCEJnP5w&s"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://banner2.cleanpng.com/20190623/yp/kisspng-python-computer-icons-programming-language-executa-1713885634631.webp"
                  className={styles.icons}
                />
              </div>
              <div className={styles.iconouter}>
                <img
                  src="https://arunpotti.files.wordpress.com/2021/12/microsoft_azure.svg_.png"
                  className={styles.icons}
                />
              </div>
            </div>
          </section>

          {/* Client Testimonials */}
          <section className={styles.section}>
            <h2>Client Testimonials</h2>
            <div className={styles.testimonials}>
              <div className={styles.testimonial}>
                <p>
                  "Webware transformed our online presence. Their team's
                  expertise is unmatched."
                </p>
                <h3>- Sarah Williams, CEO of TechCorp</h3>
              </div>
              <div className={styles.testimonial}>
                <p>
                  "Their innovative solutions helped our business grow
                  exponentially."
                </p>
                <h3>- Mark Thompson, Founder of Appify</h3>
              </div>
              {/* Add more testimonials as needed */}
            </div>
          </section>

          {/* Career Opportunities */}
          <section className={styles.section}>
            <h2>Careers at Webware</h2>
            <p>
              Join our dynamic team and be a part of a company that's shaping
              the future of technology. Check out our current openings on the{" "}
              <a href="/careers" className={styles.link}>
                Careers Page
              </a>
              .
            </p>
          </section>

          {/* Call to Action */}
          <section className={styles.ctaSection}>
            <h2>Ready to Work with Us?</h2>
            <p>
              Contact us today to discuss your project needs and find out how we
              can help your business grow.
            </p>
            <button onClick={handleGoContact} className={styles.ctaButton}>
              Get in Touch
            </button>
          </section>
        </div>
      </div>{" "}
      <Footer />
    </div>
  );
}

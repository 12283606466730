import React from "react";
import styles from "./footer.module.css";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoTiktok, IoLogoYoutube } from "react-icons/io5";
import Logo from "../../assest/logo (2).png";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  const navigate = useNavigate();
  const handleGoTermcondition = () => {
    navigate("/term&condition");
  };
  const handleGoPrivacypolicy = () => {
    navigate("/privacypolicy");
  };
  const handleGoToHome = () => {
    navigate("/");
  };
  const handleGoToAbout = () => {
    navigate("/aboutUs");
  };
  const handleGoToContactUs = () => {
    navigate("/contactUs");
  };
  const handleGoToBloges = () => {
    navigate("/bloges");
  };
  const handleGoToPortfolio = () => {
    navigate("/portfolio");
  };
  const handleGoToServices = () => {
    navigate("/services");
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerSection}>
          <img src={Logo} className={styles.navbarLogo} />
          <p>We are a company dedicated to providing the best service.</p>
        </div>
        <div className={styles.footerSection}>
          <h4>Quick Links</h4>
          <ul className={styles.footerLinks}>
            <li onClick={handleGoToHome}>Home</li>
            <li onClick={handleGoToAbout}>About</li>
            <li onClick={handleGoToServices}>Services</li>
            {/* <li onClick={handleGoToBloges}>Bloges</li> */}
            <li onClick={handleGoToPortfolio}>Portfolio</li>
            <li onClick={handleGoToContactUs}>Contact</li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h4>Contact Us</h4>
          <p>Email: info.webware001@gmail.com</p>
          <p>Phone: +61 424 803 150</p>
        </div>
        <div className={styles.footerSection}>
          <h4>Company</h4>
          <p style={{ cursor: "pointer" }} onClick={handleGoPrivacypolicy}>
            Privacy & Policy
          </p>
          <p style={{ cursor: "pointer" }} onClick={handleGoTermcondition}>
            Terms And Conditions
          </p>
        </div>
      </div>
      <div className={styles.socialIocns}>
        <div className={styles.IconOuter}>
          <FaFacebookF className={styles.Iocns} />
        </div>
        <div className={styles.IconOuter}>
          <BsTwitterX className={styles.Iocns} />
        </div>
        <div className={styles.IconOuter}>
          <AiFillInstagram className={styles.Iocns} />
        </div>
        <div className={styles.IconOuter}>
          <IoLogoYoutube className={styles.Iocns} />
        </div>
        <div className={styles.IconOuter}>
          <IoLogoTiktok className={styles.Iocns} />
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p>&copy; 2024 WebWare. All rights reserved.</p>
      </div>
    </footer>
  );
}

import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./project.module.css";

export default function Projects() {
  useEffect(() => {
    document.title = "Webware | Projects";
    window.scrollTo(0, 0);
  }, []);

  // State to track active tab
  const [activeTab, setActiveTab] = useState("Web Development");

  // Handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <NavBar />
      <div className={styles.outer}>
        <div className={styles.container}>
          <div className={styles.heroSection}>
            <h1 className={styles.heading}>Our Projects</h1>
            <p className={styles.subHeading}>
              Discover our latest projects showcasing expertise in web
              development, app solutions, and digital marketing.
            </p>
          </div>

          {/* Project tags */}
          <div className={styles.ProjectCard}>
            <div className={styles.tagflex}>
              <div
                className={styles.cursor}
                onClick={() => handleTabClick("Web Development")}
              >
                <p
                  className={`${styles.Tags} ${
                    activeTab === "Web Development" ? styles.active : ""
                  }`}
                >
                  Web Development
                </p>
              </div>

              {/* <div onClick={() => handleTabClick("Mobile App Development")}>
                <p
                  className={`${styles.Tags} ${
                    activeTab === "Mobile App Development" ? styles.active : ""
                  }`}
                >
                  Mobile App Development
                </p>
              </div> */}
            </div>
            {/* Display active tab content in individual divs */}
            <div className={styles.projectContent}>
              {activeTab === "Web Development" && (
                <div>
                  <h2>Web Development</h2>
                  <p>
                    We build responsive and modern web applications that are
                    tailored to meet the needs of businesses. Our team
                    specializes in developing user-friendly websites that drive
                    engagement.
                  </p>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://meditour.global/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>MediTour Global</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://hammerandchhattery.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Hammer & Chhattery</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="http://zmarktravel.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Z Mark Travels</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://vetcheck.it/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>VetCheck</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://albiondev.wpenginepowered.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>AEC Hotel</p>
                    </a>
                  </div>{" "}
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://www.authorsglobal.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Authors Global</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://www.flatzz.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Flatzz</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="http://qslawgroup.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Q's Law Group</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://www.authorsfy.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Authorsfy</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://politicalideas.org/ "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Political Ideas</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://www.asliproperty.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Asli Property</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://airmannet.com/ "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>AirMan</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://alh.senewt.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>ALHOS</p>
                    </a>
                  </div>{" "}
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://blog.senewt.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Point of Beginning</p>
                    </a>
                  </div>{" "}
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://www.borrelidolsson.se/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Borrelid & Olsson</p>
                    </a>
                  </div>{" "}
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://cameratechsource.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Camera Tech Source</p>
                    </a>
                  </div>{" "}
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://constructor-x.henceinterior1.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Constructor X</p>
                    </a>
                  </div>{" "}
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://dioimplant.journey-junction.tech/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Dio Implant</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://holdfolio.journey-junction.tech/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Hold folio</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://uscleanenergyauthority.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>U.S Clean Energy Authority</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://www.pureeventgroup.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Pure Event Group</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://peaceoutmoving.ca/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Peace Out Moving</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://ntp.senewt.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Ntp Center</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://medite.journey-junction.tech/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Medite</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://mn.senewt.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>insec mn</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://mutarebrand.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Mutare</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://adco.senewt.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>ADCO</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://grazle-web-57uc.vercel.app/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>GRAZLE</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://ad.senewt.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Advance Work</p>
                    </a>
                  </div>
                  <div className={styles.ProjectConatiner}>
                    <a
                      href="https://modalcreativity.net/rs/a400/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>A400</p>
                    </a>
                  </div>{" "}
                </div>
              )}

              {activeTab === "Mobile App Development" && (
                <div>
                  <h2>Mobile App Development</h2>
                  <p>
                    We create mobile apps for both Android and iOS platforms,
                    offering intuitive interfaces and robust backends to ensure
                    seamless user experiences.
                  </p>
                  <div className={styles.ProjectConatiner}>
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <p>Example</p>
                    </a>
                  </div>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

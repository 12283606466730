import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";

export default function Bloges() {
  useEffect(() => {
    document.title = "WebWare | Bloges";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div>
        <NavBar />
        Bloges
        <Footer />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import emailjs from "emailjs-com";
import style from "./form.module.css";

export default function Form() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        "service_fnvh7o9", // Replace with your EmailJS service ID
        "template_ktutoju", // Replace with your EmailJS template ID
        templateParams, // Parameters to send
        "1KI7IV5cYOKcatEcs" // Replace with your EmailJS public key
      )
      .then(
        (result) => {
          console.log(result.text);
          setFeedbackMessage("Message sent successfully!");
          setIsSubmitting(false);
          setFormData({ name: "", email: "", message: "" });
        },
        (error) => {
          console.error("Failed to send the message. Error:", error);
          setFeedbackMessage("Failed to send the message. Please try again.");
          setIsSubmitting(false);
        }
      );
  };

  return (
    <div>
      <div className={style.container}>
        <div className={style.contactFormSection}>
          <h2 className={style.formHeading}>Send Us a Message</h2>
          <form className={style.contactForm} onSubmit={sendEmail}>
            <div className={style.formGroup}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className={style.formGroup}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className={style.formGroup}>
              <label htmlFor="message">Message</label>
              <textarea
                style={{ resize: "none" }}
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className={style.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Submit"}
            </button>

            {feedbackMessage && (
              <p
                style={{ textAlign: "center" }}
                className={style.feedbackMessage}
              >
                {feedbackMessage}
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

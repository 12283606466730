import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import style from "./privacypolicy.module.css";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "WebWare | Privacy & Policy";
    window.scrollTo(0, 0);
  });
  return (
    <div className={style.outer}>
      <NavBar />
      <div className={style.heroSection}>
        <h1 className={style.heading}>Privacy Policy </h1>
        <p className={style.subHeading2}>
          {" "}
          Learn how Webware collects, uses, and protects your personal
          information.
        </p>
      </div>
      <div className={style.privacyContainer}>
        <p className={style.paragraph}>
          Welcome to Webware. Your privacy is critically important to us. This
          privacy policy explains what personal information we collect, how we
          use it, and your rights regarding that information. By using our
          services, you consent to the collection and use of your personal data
          as outlined in this policy.
        </p>

        <h2 className={style.subHeading}>1. Information We Collect</h2>
        <p className={style.paragraph}>
          We collect several types of information from and about users of our
          website and services:
        </p>
        <ul className={style.list}>
          <li>
            Personal identification information (Name, email address, phone
            number, etc.)
          </li>
          <li>Usage data (IP address, browser type, and pages visited)</li>
          <li>Cookies and tracking technologies</li>
        </ul>

        <h2 className={style.subHeading}>2. How We Use Your Information</h2>
        <p className={style.paragraph}>
          We use the information we collect in the following ways:
        </p>
        <ul className={style.list}>
          <li>To provide, operate, and maintain our services</li>
          <li>To improve, personalize, and expand our services</li>
          <li>
            To communicate with you, including customer support and
            service-related messages
          </li>
          <li>
            To send you updates, promotional materials, and marketing
            communications
          </li>
          <li>To monitor usage of our services and for analytics purposes</li>
        </ul>

        <h2 className={style.subHeading}>3. Sharing Your Information</h2>
        <p className={style.paragraph}>
          We do not share your personal information with third parties, except
          in the following cases:
        </p>
        <ul className={style.list}>
          <li>If required by law or to comply with legal obligations</li>
          <li>To protect our rights and safety</li>
          <li>
            To trusted third-party service providers who assist us in operating
            our services
          </li>
        </ul>

        <h2 className={style.subHeading}>4. Your Data Protection Rights</h2>
        <p className={style.paragraph}>
          You have the following rights regarding your personal information:
        </p>
        <ul className={style.list}>
          <li>
            Right to access – You have the right to request copies of your
            personal data.
          </li>
          <li>
            Right to rectification – You have the right to request that we
            correct any information you believe is inaccurate.
          </li>
          <li>
            Right to erasure – You have the right to request that we erase your
            personal data under certain conditions.
          </li>
          <li>
            Right to restrict processing – You have the right to request that we
            restrict the processing of your personal data under certain
            conditions.
          </li>
          <li>
            Right to data portability – You have the right to request that we
            transfer your data to another organization or directly to you.
          </li>
        </ul>

        <h2 className={style.subHeading}>5. Security of Your Information</h2>
        <p className={style.paragraph}>
          We take the security of your personal information seriously and
          implement appropriate technical and organizational measures to protect
          it from unauthorized access, alteration, or destruction. However, no
          method of transmission over the internet or electronic storage is 100%
          secure, and we cannot guarantee absolute security.
        </p>

        <h2 className={style.subHeading}>6. Cookies</h2>
        <p className={style.paragraph}>
          Our website uses cookies to enhance user experience and analyze site
          usage. Cookies are small files that are stored on your device. You can
          disable cookies in your browser settings, but this may affect your
          ability to use certain features of our website.
        </p>

        <h2 className={style.subHeading}>7. Changes to This Privacy Policy</h2>
        <p className={style.paragraph}>
          We may update our privacy policy from time to time. We encourage you
          to review this page periodically for any changes. Changes are
          effective when posted on this page.
        </p>

        <h2 className={style.subHeading}>8. Contact Us</h2>
        <p className={style.paragraph}>
          If you have any questions about this privacy policy, the practices of
          this site, or your dealings with this site, please contact us at:
        </p>
        <p className={style.contactInfo}>
          <strong>Email:</strong> support@webware.com <br />
          <strong>Phone:</strong> +123 456 7890
        </p>
      </div>
      <Footer />
    </div>
  );
}

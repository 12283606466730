import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import style from "./portfolio.module.css";
import { useNavigate } from "react-router-dom";

// Sample Portfolio Data
const projects = [
  {
    id: 1,
    title: "E-Commerce Website",
    description:
      "A fully functional e-commerce platform with user authentication, product listings, and a shopping cart.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZNhGHhxeDCDYLqSQfqvZuwq6w3XabWAkYaA&s",
  },
  {
    id: 2,
    title: "Mobile App Development",
    description:
      "A mobile application for Android and iOS, featuring a user-friendly interface and real-time updates.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPzB3bS2psJF1lqzTj3Pg2HTuGIsAtRESANg&s",
  },
  {
    id: 3,
    title: "Portfolio Website",
    description:
      "A personal portfolio site to showcase skills and projects, built with React and CSS Modules.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvivYH4muwXl69UaOHfi6dLmss7xhcAhgGtQ&s",
  },
  {
    id: 4,
    title: "Blog Platform",
    description:
      "A blogging platform that allows users to create and manage their own blogs with social sharing options.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtrwQ6_vCEgGVYlaZ8aevIwlNCQRssgObGew&s",
  },
  {
    id: 5,
    title: "Social Media App",
    description:
      "A social media application with features like posts, comments, and messaging, promoting user engagement.",

    image:
      "https://www.wscubetech.com/blog/wp-content/uploads/2024/04/social-media-1024x683.webp",
  },
  {
    id: 6,
    title: "Word Press",
    description:
      "A web-based task management tool for teams to collaborate, assign tasks, and track progress.",
    image:
      "https://www.seoclerk.com/pics/000/872/094/3b1d7e4ae14bd472645a004040bca8c5.jpg",
  },
  {
    id: 7,
    title: "Online Learning Platform",
    description:
      "An online education platform offering courses and resources for learners of all ages.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDISxPbxCJAp6NER6rDMcFf_9a54qCsDf8cg&s",
  },
  {
    id: 8,
    title: "Restaurant Reservation System",
    description:
      "A reservation system for restaurants, allowing users to book tables and view menus online.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSot9yJ9rwEwPZOMs6EEg0cOgYoHPlhwN6-8A&s",
  },
  {
    id: 9,
    title: "Fitness Tracker App",
    description:
      "A mobile app designed to help users track their fitness activities and goals with personalized insights.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6K48OgcLy3EaaLpFoQ6bnxyYAn6E6EONsUg&s",
  },
];

const Portfolio = () => {
  useEffect(() => {
    document.title = "WebWare | Portfolio";
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  const handleGoProjects = () => {
    navigate("/projects");
  };
  return (
    <div className={style.portfolioOuter}>
      <NavBar />
      <div className={style.portfolioContainer}>
        <div className={style.heroSection}>
          <h1 className={style.heading}>Our Portfolio</h1>
          <p className={style.subHeading}>
            Discover our latest projects showcasing expertise in web
            development, app solutions, and digital marketing.
          </p>
        </div>
        <div className={style.projectsGrid}>
          {projects.map((project) => (
            <div key={project.id} className={style.projectCard}>
              <img
                src={project.image}
                alt={project.title}
                className={style.projectImage}
              />
              <h2 className={style.projectTitle}>{project.title}</h2>
              <p className={style.projectDescription}>{project.description}</p>
              <button onClick={handleGoProjects} className={style.projectLink}>
                View Projects
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;

import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import style from "./termcodition.module.css";

export default function TermsCondition() {
  useEffect(() => {
    document.title = "WebWare | Terms & Condition";
    window.scrollTo(0, 0);
  });

  return (
    <div className={style.outer}>
      <NavBar />
      <div className={style.heroSection}>
        <h1 className={style.heading}>Terms and Conditions </h1>
        <p className={style.subHeading2}>
          Review our terms before using Webware's services. These outline your
          rights and obligations.
        </p>
      </div>
      <div className={style.termsContainer}>
        <p className={style.paragraph}>
          Welcome to Webware! These terms and conditions outline the rules and
          regulations for the use of our website, located at www.webware.com. By
          accessing this website, we assume you accept these terms and
          conditions in full. Do not continue to use Webware if you do not
          accept all the terms and conditions stated on this page.
        </p>

        <h2 className={style.subHeading}>1. Intellectual Property Rights</h2>
        <p className={style.paragraph}>
          Unless otherwise stated, Webware and/or its licensors own the
          intellectual property rights for all material on Webware. All
          intellectual property rights are reserved. You may view and/or print
          pages from www.webware.com for your personal use, subject to
          restrictions set in these terms and conditions.
        </p>

        <h2 className={style.subHeading}>2. Restrictions</h2>
        <p className={style.paragraph}>
          You are specifically restricted from the following:
        </p>
        <ul className={style.list}>
          <li>Publishing any website material in any other media</li>
          <li>
            Selling, sublicensing, or otherwise commercializing any website
            material
          </li>
          <li>
            Using this website in any way that is, or may be, damaging to the
            website
          </li>
          <li>
            Using this website in any way that impacts user access to this
            website
          </li>
          <li>
            Using this website contrary to applicable laws and regulations
          </li>
          <li>
            Engaging in any data mining, data harvesting, or similar activity on
            this website
          </li>
        </ul>

        <h2 className={style.subHeading}>3. Your Privacy</h2>
        <p className={style.paragraph}>
          Please read our{" "}
          <a href="/privacy-policy" className={style.link}>
            Privacy Policy
          </a>{" "}
          to understand how we handle your personal information.
        </p>

        <h2 className={style.subHeading}>4. Limitation of Liability</h2>
        <p className={style.paragraph}>
          In no event shall Webware, nor any of its officers, directors, or
          employees, be held liable for anything arising out of or in any way
          connected with your use of this website. Webware, including its
          officers, directors, and employees, shall not be held liable for any
          indirect, consequential, or special liability arising from your use of
          this website.
        </p>

        <h2 className={style.subHeading}>5. Indemnification</h2>
        <p className={style.paragraph}>
          You hereby indemnify Webware to the fullest extent against any and/or
          all liabilities, costs, demands, causes of action, damages, and
          expenses arising in any way related to your breach of any provisions
          of these terms.
        </p>

        <h2 className={style.subHeading}>6. Termination</h2>
        <p className={style.paragraph}>
          We may terminate or suspend access to our website immediately, without
          prior notice or liability, for any reason, including if you breach
          these terms.
        </p>

        <h2 className={style.subHeading}>7. Governing Law</h2>
        <p className={style.paragraph}>
          These terms and conditions will be governed by and construed in
          accordance with the laws of [Insert Your Country/State], and you
          submit to the non-exclusive jurisdiction of the courts in [Insert Your
          Country/State] for the resolution of any disputes.
        </p>

        <h2 className={style.subHeading}>8. Modifications to These Terms</h2>
        <p className={style.paragraph}>
          Webware reserves the right to revise these terms at any time as it
          sees fit. By using this website, you are expected to review these
          terms on a regular basis to ensure you understand all terms and
          conditions governing use of this website.
        </p>

        <h2 className={style.subHeading}>9. Contact Information</h2>
        <p className={style.paragraph}>
          If you have any questions about these Terms and Conditions, please
          contact us at:
        </p>
        <p className={style.contactInfo}>
          <strong>Email:</strong> support@webware.com <br />
          <strong>Phone:</strong> +123 456 7890
        </p>
      </div>
      <Footer />
    </div>
  );
}

// src/pages/HomePage.js
import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./home.module.css";
import TopTreatment from "../Components/HomeSlider/TopTreatments";
import Form from "../Components/Form";

const HomePage = () => {
  useEffect(() => {
    document.title = "WebWare | Home";
    window.scrollTo(0, 0);
  });
  return (
    <div className={styles.outer}>
      <NavBar />
      <section className={styles.hero}>
        <div className={styles.overlay}>
          <div className={styles.w60}>
            {/* Overlay */}
            <h1 className={styles.heroTitle}>Welcome to WebWare</h1>
            <p className={styles.heroSubtitle}>
              Professional Web Development, SEO, and App Solutions
            </p>
          </div>
        </div>
      </section>
      <div className={styles.container}>
        <div className={styles.heroSection}>
          <h1 className={styles.heading}>About Us</h1>
          <p className={styles.subHeading}>
            Committed to delivering innovative solutions tailored to your needs.
          </p>
        </div>
        <section className={styles.aboutUsSection}>
          <div className={styles.solutionsSection}>
            {/* Image Section */}
            <div className={styles.imageSection}>
              <img
                src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dGVjaHxlbnwwfHwwfHx8MA%3D%3D"
                alt="About Us"
                className={styles.aboutImage}
              />
            </div>

            {/* Text Section */}
            <div className={styles.textSection}>
              <p className={styles.description}>
                At WebWare, we recognize that innovation drives success in
                today’s fast-paced digital world. Our passionate team of
                professionals is dedicated to providing state-of-the-art
                solutions designed specifically for your business requirements.
                Whether you need an engaging website that captivates your
                audience, a powerful mobile application to enhance user
                experience, or custom software that optimizes your processes, we
                are here to assist you.
              </p>
              <button className={styles.learnMoreButton}>About Us</button>
            </div>
          </div>
        </section>
        <div>
          <div className={styles.heroSection}>
            <h1 className={styles.heading}>
              Innovative IT Solutions for Your Business
            </h1>
            <p className={styles.subHeading}>
              We build websites, apps, and custom software that grow your
              business
            </p>
          </div>
        </div>
        <section className={styles.solutionsSection}>
          <div className={styles.textSection}>
            <p className={styles.description}>
              At WebWare, we understand that technology is the backbone of
              modern business. Our dedicated team of experts is committed to
              delivering cutting-edge IT solutions tailored to your unique
              needs. Whether you require a sleek, user-friendly website, a
              robust mobile app, or bespoke software that streamlines your
              operations, we’ve got you covered. Partner with us to unlock your
              business's full potential and achieve your goals.
            </p>
            <button className={styles.learnMoreButton}>Portfolio</button>
          </div>
          <div className={styles.imageSection}>
            <img
              src="https://media.istockphoto.com/id/1344939844/photo/hand-holding-drawing-virtual-lightbulb-with-brain-on-bokeh-background-for-creative-and-smart.jpg?s=612x612&w=0&k=20&c=2GLUy6eqCSr0NFRO8CHm8_PUMy9Qc8ryqcsRoe0DEYM=" // Replace with your image URL
              alt="Innovative IT Solutions"
              className={styles.aboutImage}
            />
          </div>
        </section>
        <div>
          <div className={styles.heroSection}>
            <h1 className={styles.heading}>Our Services</h1>
            <p className={styles.subHeading}>We're here to help you!</p>
          </div>
          <TopTreatment />
        </div>
        <div style={{ marginTop: "40px" }}>
          <Form />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;

import React, { useEffect } from "react";
import style from "./contsctus.module.css";
import { HiOutlinePhone } from "react-icons/hi";
import { TbMessage2 } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import Form from "../Components/Form";

export default function ContactUs() {
  useEffect(() => {
    document.title = "Webware | Contact Us";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBar />
      <div className={style.contactPage}>
        <div className={style.heroSection}>
          <h1 className={style.heading}>Contact Us</h1>
          <p className={style.subHeading}>We're here to help you!</p>
        </div>

        <div className={style.container}>
          <div style={{ marginBottom: "40px" }}>
            <Form />
          </div>

          <div className={style.contactDetails}>
            <div className={style.info}>
              <HiOutlinePhone className={style.icon} />
              <p>+61 424 803 150</p>
            </div>
            <div className={style.info}>
              <MdOutlineMailOutline className={style.icon} />
              <p>info.webware001@gmail.com</p>
            </div>
            <div className={style.info}>
              <SlLocationPin className={style.icon} />
              <p>24 oxford street boxhill 3128 Victoria Melbourne Australia</p>
            </div>
          </div>

          <div className={style.map}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.6571036790606!2d145.1177809760571!3d-37.82149993448051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad640b99cab7887%3A0xbc3aba05618ae6b4!2s24%20Oxford%20St%2C%20Box%20Hill%20VIC%203128%2C%20Australia!5e0!3m2!1sen!2s!4v1727948255609!5m2!1sen!2s"
              width="100%"
              height="400"
              frameBorder="0"
              style={{ border: "0" }}
              allowFullScreen=""
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React from "react";
import Carousel from "react-multi-carousel";
import classNames from "classnames";
import "react-multi-carousel/lib/styles.css";
import style from "./homeslider.module.css";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";

const responsive = {
  lgdesktop: {
    breakpoint: { max: 3000, min: 1441 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1041 },
    items: 3,
    slidesToSlide: 1,
  },
  Laptop: {
    breakpoint: { max: 1040, min: 769 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 521 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 520, min: 320 },
    items: 1,
    slidesToSlide: 1,
  },
};
const CustomLeftArrow = ({ onClick }) => (
  <button
    className={classNames(style.customArrow, style.leftArrow)}
    onClick={onClick}
  >
    <FaArrowLeft />
  </button>
);

// Define custom right arrow component
const CustomRightArrow = ({ onClick }) => (
  <button
    className={classNames(style.customArrow, style.rightArrow)}
    onClick={onClick}
  >
    <FaArrowRight />
  </button>
);

const services = [
  {
    id: 1,
    title: "Web Development",
    description:
      "Building responsive and engaging websites to enhance your online presence.",
    image:
      "https://api.reliasoftware.com/uploads/web_development_is_important_176fa0618e.jpg",
  },
  {
    id: 2,
    title: "Mobile App Development",
    description:
      "Creating user-friendly mobile applications for Android and iOS platforms.",
    image:
      "https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg",
  },
  {
    id: 3,
    title: "Social Media Marketing",
    description:
      "Effective strategies to promote your brand and engage with your audience on social media.",
    image:
      "https://codedesign.org/storage/app/media/uploaded-files/Social%20Media%20Marketing%20-%202022.png",
  },
  {
    id: 4,
    title: "SEO Services",
    description:
      "Optimizing your website to rank higher on search engines and attract organic traffic.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwHj1-mV6DMRnruec6S6H-OZxpXEjIedt8Lg&s",
  },
  {
    id: 5,
    title: "Content Creation",
    description:
      "Crafting high-quality content tailored to your brand’s voice and audience needs.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-YEXrFXBzC-J9zbuD2sO_kdq4NgDudlEyWA&s",
  },
  {
    id: 6,
    title: "Graphic Design",
    description:
      "Creating visually appealing designs for your brand, including logos and marketing materials.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8FVkfCVQk_eEH19SjtNRXzWpWpHquroB3zA&s",
  },
  {
    id: 7,
    title: "E-commerce Solutions",
    description:
      "Developing online stores with robust features to enhance customer experience.",
    image:
      "https://onextstudio.com/wp-content/uploads/2024/06/ecommerce-solutions.jpg",
  },
  {
    id: 8,
    title: "Website Maintenance",
    description:
      "Regular updates and maintenance to keep your website running smoothly.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnZgGI6Lfkp63v-3r1-NR1wSod5AxGdmqNqA&s",
  },
  {
    id: 9,
    title: "Cloud Services",
    description:
      "Offering cloud solutions to store, manage, and access your data securely.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAxp095IBlY3mkY90PzA7b1k3tlGTGi2Kg_A&s",
  },
  {
    id: 10,
    title: "Digital Marketing",
    description:
      "Comprehensive digital marketing strategies to grow your business online.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJKVPSpPVkPeWJa3oODq7A1i5oD19WwEjxgA&s",
  },
  {
    id: 11,
    title: "Video Production",
    description:
      "Creating high-quality videos for marketing, training, or promotional purposes.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNje8WRLtaPmoXCiBU9uveGrmxgrhko8JG6w&s",
  },
  {
    id: 12,
    title: "Consulting Services",
    description:
      "Expert advice and strategies to help your business thrive in the digital landscape.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREW8w74EQpWSHHzj1RMjhZRaaFOmCytiKDgw&s",
  },
  {
    id: 13,
    title: "UI/UX Design",
    description:
      "Designing intuitive user interfaces and experiences for your digital products.",
    image: "https://www.izeoninnovative.com/izeon/img/imgs/blog/ui.jpg",
  },
  {
    id: 14,
    title: "Email Marketing",
    description:
      "Creating effective email campaigns to engage your audience and boost conversions.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSrNmOK32P1GZHAxc2gofd0SHiazkXQloO9w&s",
  },
  {
    id: 15,
    title: "Data Analytics",
    description:
      "Analyzing data to provide insights that drive informed business decisions.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBtHMCbOnU_5ZXnYyXJb6BOM8hJDLS3wYnlw&s",
  },
];

const TopTreatment = () => {
  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        dotListClass="custom-dot-list-style"
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {services.map((item, index) => (
          <div className={style.slider} key={index}>
            <img src={item.image} className={style.imgs} alt={item.title} />
            <div className={classNames(style.headingouter)}>
              <p className={classNames(style.heading)}>{item.title}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TopTreatment;
